import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function Users({ isAuthenticated }) {
  const navigate = useNavigate();

  return (
    <h1>
      USERS
    </h1>
  );
}
