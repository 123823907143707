import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import AdbIcon from "@mui/icons-material/Adb";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import { useLocation, useNavigate, Link, Outlet, NavLink } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import { useSelector } from "react-redux";
import useAuth from "../hooks/useAuth";
import { deepOrange, deepPurple } from '@mui/material/colors';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";


function EasyAppBar() {
  
  const currentUser = useAuth()
  //console.log("APPBAR", currentUser)
  
  const currentUserEmail = currentUser?.auth?.user?.signInUserSession.idToken.payload.email;
  const currentUserName = currentUser?.auth?.user?.signInUserSession.idToken.payload.name;
  
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { setAuth } = useAuth();

  const currentWorkspace = useSelector(
    (state) => state.workspaces.currentWorkspace
  );

  const usersWorkspaces = useSelector(
    (state) => state.workspaces.usersWorkspaces
  );
  const workspacesStatus = useSelector((state) => state.workspaces.fetchStatus);

  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    if (name) {
    let initials = ""
    const words = name.split(' ')

    if (words.length < 1) {
       initials = "N"
    } else if (words.length === 1) {
       initials = name.split(' ')[0][0]
    } else if ( words.length > 2 | words.length === 2) {
       initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } 
    
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  }
  }
  
  async function signOut() {
    try {
      const data = await Auth.signOut();
      console.log("SIGN OUT", data)
      setAuth(null)
      navigate("/login", { state: { from: location } }, { replace: true });
    } catch (err) {
      console.log(err);
    }
  }

  const pages = [
    {
      item: "Users",
      onClick: handleCloseNavMenu,
      link: "/users",
      type: "menuItem",
    },
  ];

  const userMenu = [
    {
      item: currentUserName,
      onClick: handleCloseUserMenu,
      type: "menuList",
      variant: "h5",
    },
    {
      item: currentUserEmail,
      onClick: handleCloseUserMenu,
      type: "menuList",
      variant: "inherit",
    },
    {
      item: "Profile Settings",
      onClick: handleCloseUserMenu,
      link: "profile",
      type: "menuItem",
    },
    {
      item: "Logout",
      onClick: signOut,
      //link: "/login",
      type: "menuItem",
    },
  ];

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MoreTimeOutlinedIcon
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          />
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              TIMEN
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {/* ----------------------------- Small (icon) menu ----------------------------- */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, key) => (
                <MenuItem key={key} onClick={page.onClick}>
                  <Typography textAlign="center">
                    <Link
                      to={page.link}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {page.item}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <MoreTimeOutlinedIcon
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            TIMEN
          </Typography>
          {/* ----------------------------- AppBar menu ------------------------------- */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, key) => (
              <Button
                key={key}
                onClick={page.onClick}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Link
                  to={page.link}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {page.item}
                </Link>
              </Button>
            ))}
          </Box>

          {/* --------------------------Select Workspace ------------------------ */}

            {/* {getStatus === "updating" && <Circular />} */}
            <FormControl sx={{ m: 1, minWidth: 180, mr: 3 }} size="small">
              <InputLabel id="demo-simple-select-standard-label">
                Workspace
              </InputLabel>
              {currentWorkspace.id && (
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="workspaceID"
                  value={currentWorkspace.id}
                  onChange={handleSelectChange}
                  label="Workspace"
                  size="small"
                >
                  {usersWorkspaces.map((item, index) => (
                    <MenuItem value={item.id} key={item.id}>
                      <ListItemText primary={`${item.name}`} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>

            {/* --------------------------End Select Workspace ------------------------ */}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={currentUserName} {...stringAvatar(currentUserName)}  />
              </IconButton>
            </Tooltip>

            {/* -------------------------- Start User Menu ------------------------ */}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {userMenu.map((item, key) =>
                item.type === "menuItem" ? (
                  <MenuItem key={key} onClick={item.onClick}>
                    <Typography>
                      <Link
                        to={item.link}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {item.item}
                      </Link>
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuList key={key} onClick={item.onClick}>
                    <Typography
                      textAlign="left"
                      ml={2}
                      mr={3}
                      variant={item.variant}
                    >
                      {item.item}
                    </Typography>
                  </MenuList>
                )
              )}
            </Menu>
            {/* -------------------------- End User Menu ------------------------ */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default EasyAppBar;
