import React, { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import Layout from "./layout";
import { Auth, Hub } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./loading";
import useAuth from "../hooks/useAuth";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { workspacesByUser } from "../store/workspacesSlice";

const RequireAuth = ({ currentUser }) => {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { auth, setAuth } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function CheckUser() {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      //console.log("REQUIRE Auth UseEffect", user);

      setAuth({ user });
      dispatch(workspacesByUser(user.signInUserSession.idToken.payload.sub));
    } catch (err) {
      console.log("FIRST ERROR", err);
      setAuth(null);
      //navigate("/login", { state: { from: location } }, { replace: true });
      //navigate("/login", { replace: true });
      navigate("/login");
    }
  }

  useEffect(() => {
    CheckUser();
  }, []);

  return (
    <>
      {console.log("RETURN CURRENT USER", currentUser)}
      {auth?.user === null && (
        <Loading message="1234567890" isAuth={currentUser} />
      )}
      {console.log("CURRENT USER", currentUser)}
      {auth?.user.signInUserSession?.idToken?.payload ? (
        <>
          <Layout />
        </>
      ) : (
        <>
          {console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")}
          {/* <Navigate to="/login/" state={{ from: location }} replace /> */}
        </>
      )}
    </>
  );
};

export default RequireAuth;
