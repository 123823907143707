import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Auth } from "aws-amplify";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

export default function CodeConfirm({
  formState,
  onChange,
  setUiState,
  codeConfirm,
  resendCode,
  message,
  setMessage,
}) {
  return (
    <DialogContent>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Box>
          <Typography variant="h5">Confirm code</Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <TextField
            disabled
            required
            sx={{ width: "100%" }}
            id="outlined-required"
            label="Email"
            value={formState.email}
            name="email"
            onChange={onChange}
            size="small"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            required
            sx={{ width: "100%", mt: 2 }}
            id="code-required"
            label="Code"
            value={formState.authCode}
            name="authCode"
            onChange={onChange}
            size="small"
          />
        </Box>

        <Box>
          <Chip
            sx={{ width: "100%", mt: 2 }}
            label="Confirm code"
            color="warning"
            onClick={codeConfirm}
          />
        </Box>

        <Box>
          <Chip
            sx={{ width: "100%", mt: 2 }}
            label="Resend code"
            color="warning"
            onClick={resendCode}
          />
        </Box>
        <Box>
          <Chip
            sx={{ width: "100%", mt: 2 }}
            label="Cancel"
            color="warning"
            onClick={() => {
              setMessage({ message: null, severity: null });
              setUiState("signUpDialog");
            }}
          />
        </Box>
        <Stack>
          <Box sx={{ mt: 2 }}>
          {message.message ? (
            <Stack sx={{ width: "100%", height: "100px" }} spacing={2}>
              <Alert variant="outlined" severity={message.severity}>
                {message.message}
              </Alert>
            </Stack>
          ) : (
            <Stack sx={{ width: "100%", height: "100px" }} spacing={2}></Stack>
          )}
        </Box>
        </Stack>
      </Box>
    </DialogContent>
  );
}
