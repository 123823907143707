import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function LogInTitle() {
  return (
    <Box justifyContent="right">
      <Typography letterSpacing="60px" variant="h2" display="inline">
        LOADIN
      </Typography>
      <Typography variant="h2" display="inline">
        G
      </Typography>
    </Box>
  );
}
