import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Authenticator,
  withAuthenticator,
  Button,
  Heading,
  AmplifySignOut,
  CheckboxField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import awsExports from "./aws-exports";
//import { v4 as uuid } from "uuid";
import Container from "@mui/material/Container";
import { Route, Routes } from "react-router";
//import Users from "./components/users/users";
//import Projects from "./components/projects/projectList/projects";
//import Clients from "./components/new_clients/clients";
//import Reports from "./components/reports/reports"
//import ResponsiveAppBar from "./components/appbar";
import { useSelector } from "react-redux";
import { userGroup } from "./store/adminSlice";
import { workspacesByUser } from "./store/workspacesSlice";
import { getUserDataById } from "./store/usersSlice";
import { getCurrentUser } from "./store/adminSlice";
import { useDispatch } from "react-redux";
import Layout from "./routeComponents/layout";
import Homepage from "./routeComponents/pages/homepage/homepage";
import ProfileSettings from "./routeComponents/pages/profile/profileSettings";
import Users from "./routeComponents/pages/users/users";

//import Router from "./router";
import HubLogin from "./routeComponents/hubLogin";
import SignIn from "./routeComponents/auth/signIn";
import SignUp from "./routeComponents/auth/signUp";
import CodeConfirm from "./routeComponents/auth/codeConfirm";

import Conditions from "./routeComponents/conditions";
import RequireAuth from "./routeComponents/requireAuth";
import { Link, Outlet, NavLink, useLocation, Navigate,  useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";

export default function App() {

  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  console.log("APP FROM", from)

  const [hubEvent, setHubEvent] = useState("notAuthenticate");
  //const { route } = useAuthenticator((context) => [context.route]);

  return (
    <>
      <Routes>

        {/* protected routes */}
        <Route path="/" element={<RequireAuth />}>
          <Route index element={<Homepage />} />
          <Route path="users" element={<Users />} />
          <Route path="terms" element={<Conditions />} />
          <Route path="profile" element={<ProfileSettings />} />
        </Route>

        {/* public routes */}
        <Route path="login" element={<HubLogin />} />
        <Route path="conditions" element={<Conditions />} />
      </Routes>
    </>
  );
}
