import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API, graphqlOperation } from "aws-amplify";
import { usersByWorkspace as UsersByWorkspace } from "../appQueries/appQueries"

export const usersByWorkspace = createAsyncThunk(
    'users/workspaceUsers',
    async(workspaceId, { rejectWithValue, dispatch }) => {
        try {
            const response = await API.graphql(
                graphqlOperation(UsersByWorkspace, { workspaceID: workspaceId }));
            dispatch(listUsers(response.data))
            console.log("GETUSERDATAAAAAAAAAABYIDDDDDDDDDDDD", response.data)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    })

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        items: [],
        pageName: 'Users',
        userColumns: ['User Name', 'Email', 'User role', ],
        adminColumns: ['Edit user'],
        createButtonName: 'Create',
        updateButtonName: 'Update',
        createDialogName: 'Create user',
        updateDialogName: 'Update user',
        currentUser: {},
        currentUserdefaultWorkspace: "",
        fetchStatus: null,
        createStatus: null,
        deleteStatus: null,
        updateStatus: null,
        getStatus: null,
        error: null,
    },

    reducers: {
        listUsers(state, action) {
            state.items = action.payload.usersByWorkspace.items
        }
    },
    extraReducers: {
        [usersByWorkspace.pending]: (state, action) => {
            state.fetchStatus = 'loading';
            //console.log('Users loading')
            state.error = null;
        },
        [usersByWorkspace.fulfilled]: (state, action) => {
            state.fetchStatus = 'loaded'
                //console.log('Users loaded')
            state.error = null;
        },
        [usersByWorkspace.rejected]: (state, action) => {
            state.fetchStatus = 'rejected';
            //console.log('Users rejected')
            state.error = action.payload
        },

    }
});

export const { listUsers } = usersSlice.actions;
export default usersSlice.reducer;