import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Auth } from "aws-amplify";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function SignUp({
  formState,
  onChange,
  setUiState,
  signUp,
  message,
  setMessage,
  setFormState,
  initialState,
}) {
  const navigate = useNavigate();

  return (
    <DialogContent>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Box>
          <Typography variant="h5">Sign Up</Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            sx={{ width: "100%" }}
            required
            id="email-required"
            label="Email"
            value={formState.email}
            name="email"
            onChange={onChange}
            size="small"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            sx={{ width: "100%", mt: 2 }}
            required
            id="userName-required"
            label="User name"
            value={formState.userName}
            name="userName"
            onChange={onChange}
            size="small"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            sx={{ width: "100%", mt: 2 }}
            required
            id="filled-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={formState.password}
            name="password"
            onChange={onChange}
            size="small"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            sx={{ width: "100%", mt: 2 }}
            required
            id="confirm-password-input"
            label="Confirm Password"
            type="password"
            autoComplete="current-password"
            value={formState.confirmPassword}
            name="confirmPassword"
            onChange={onChange}
            size="small"
          />
        </Box>
        
       {/* --------------------------NewCheckBox--------------------- */}
        <Stack>
        <Box>
          <Checkbox
            checked={formState.conditionsChecked}
            onChange={onChange}
            size="small"
            //inputProps={{ "aria-label": "controlled" }}
            name="conditionsChecked"
          ></Checkbox>
          <Typography display="inline">
            I agree to <Link to="/conditions"style={{ textDecoration: "none", color: "darkblue"}}>Terms and Conditions</Link>
          </Typography>
        </Box>
        </Stack>


        <Box>
          <Chip
            sx={{ width: "100%", mt: 0 }}
            label="Sign Up"
            color="warning"
            onClick={signUp}
          />
        </Box>
        <Stack alignItems="center">
          <Typography sx={{ mt: 1 }}>Already have account?</Typography>
          <Button
            onClick={() => {
              setFormState(initialState)
              setUiState("signInDialog");
              setMessage({ message: null, severity: null });
            }}
          >
            Sign In
          </Button>
        </Stack>

        <Box>
          {message.message ? (
            <Stack sx={{ width: "100%", height: "100px" }} spacing={2}>
              <Alert variant="outlined" severity={message.severity}>
                {message.message}
              </Alert>
            </Stack>
          ) : (
            <Stack sx={{ width: "100%", height: "100px" }} spacing={2}></Stack>
          )}
        </Box>
      </Box>
    </DialogContent>
  );
}
