import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import GoogleIcon from "@mui/icons-material/Google";

import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

export default function SignIn({
  formState,
  onChange,
  setUiState,
  signIn,
  googleSignIn,
  resendCode,
  message,
  setMessage
}) {
  return (
    <DialogContent>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Box>
          <Typography variant="h5">Sign In</Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            sx={{ width: "100%" }}
            required
            id="outlined-required"
            label="Email"
            value={formState.email}
            name="email"
            autoComplete="current-email"
            // onChange={handleInputEmailChange}
            onChange={onChange}
            size="small"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            sx={{ width: "100%", mt: 2 }}
            required
            id="filled-password-input"
            label="Password"
            type="password"
            value={formState.password}
            name="password"
            onChange={onChange}
            size="small"
          />
        </Box>
        <Box>
          <Button
            sx={{ mt: 1 }}
            onClick={() => {
              setMessage({message: null, severity: null})
              setUiState("forgotPasswordDialog");
            }}
          >
            Forgot password?
          </Button>
        </Box>
        <Box>
          <Chip
            sx={{ width: "100%", mt: 1 }}
            label="Sign In"
            color="warning"
            onClick={signIn}
          />
        </Box>
        <Divider>or</Divider>
        <Box>
          <Chip
            sx={{ width: "100%" }}
            onClick={googleSignIn}
            icon={<GoogleIcon />}
            label="Sign In with Google"
            color="warning"
          />
        </Box>
        <Stack alignItems="center">
          <Typography sx={{ mt: 1 }}>Don't have an account?</Typography>
          <Button
            onClick={() => {
              setUiState("signUpDialog");
              setMessage({message: null, severity: null})
            }}
          >
            Sign Up
          </Button>
        </Stack>
        
{/* ------------------------------ new messages ---------------------------------- */}
        <Box>
          {message.message ? (
            <>
              {message.message === "User is not confirmed." && (
                <>
                  <Stack sx={{ width: "100%", height: "100px" }} spacing={2}>
                    <Alert
                      variant="outlined"
                      severity={message.severity}
                      action={
                        <Button
                          size="small"
                          onClick={() => {
                            resendCode();
                            setUiState("signUp");
                            setMessage({message: null, severity: null})
                          }}
                        >
                          confirm
                        </Button>
                      }
                    >
                      {message.message}
                    </Alert>
                  </Stack>
                </>
              )}
              {message.message !== "User is not confirmed." && (
                <Stack sx={{ width: "100%", height: "100px" }} spacing={2}>
                  <Alert variant="outlined" severity={message.severity}>
                    {message.message}
                  </Alert>
                </Stack>
              )}
            </>
          ) : (
            <Stack sx={{ width: "100%", height: "100px" }} spacing={2}></Stack>
          )}
        </Box>


      </Box>
    </DialogContent>
  );
}
