import { configureStore } from '@reduxjs/toolkit'
// import projectsReducer from './projectsSlice'
// import clientsReducer from './clientsSlice'
import adminReducer from './adminSlice'
import usersReducer from './usersSlice'
import workspacesReducer from './workspacesSlice'
// import tasksReducer from './tasksSlice'
// import projectsUsersReducer from './projectsUsersSlice'
// import eventsReducer from './eventsSlice'

const store = configureStore({
    reducer: {
        //projects: projectsReducer,
        //clients: clientsReducer,
        admin: adminReducer,
        users: usersReducer,
        workspaces: workspacesReducer,
        //tasks: tasksReducer,
        //projectUsers: projectsUsersReducer,
        //events: eventsReducer,
    },
});

export default store