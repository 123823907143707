/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://7exdoomjangevhwghxed7a4kse.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-mbtcc4jtabcexgcti5lp5nafdm",
    "aws_cognito_identity_pool_id": "eu-central-1:83c67cdf-bb35-4e76-9ad3-95f69cb3fe58",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_OJalcKHuB",
    "aws_user_pools_web_client_id": "74c08o5udnp5clu8qdas01h8lv",
    "oauth": {
        "domain": "timendec45d17-dec45d17-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "openid"
        ],
        "redirectSignIn": "https://timen.app/,http://localhost:3000/",
        "redirectSignOut": "https://timen.app/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "timen-20230830180933-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dd9y02p66yalx.cloudfront.net"
};


export default awsmobile;
