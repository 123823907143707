import { Link, Outlet } from "react-router-dom";
import EasyAppBar from "../routeComponents/easyAppbar"
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import { workspacesByUserId } from "../store/workspacesSlice";
import { getUserDataById } from "../store/usersSlice";

const Layout = ({ currentUser }) => {
  
  return (
    <>
      <EasyAppBar currentUser={currentUser}></EasyAppBar>
      <Container
        maxWidth="fixed"
        sx={{
          mt: "1rem",
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export default Layout;
