import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { useNavigate, Link, Outlet, NavLink } from "react-router-dom";
import Chip from "@mui/material/Chip";
import SignIn from "./auth/signIn";
import SignUp from "./auth/signUp";
import CodeConfirm from "./auth/codeConfirm";
import ForgotPassword from "./auth/forgotPassword";
import NewPassword from "./auth/newPassword";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import LoadingTitle from "./loadingTitle";
import useAuth from "../hooks/useAuth";
import { workspacesByUser } from "../store/workspacesSlice";

export default function Loading({message, isAuth}) {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          sx={{ height: "700px" }}
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <LoadingTitle />
          <Box>{message}</Box>
          <Box>{isAuth}</Box>
        </Stack>
      </Box>
    </>
  );
}
