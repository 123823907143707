import React, { useEffect } from "react";

function Homepage() {
  
  return (
    <>
    <h1>
      HOMEPAGE
    </h1>
    </>
  );
}
export default Homepage;
