import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API, graphqlOperation } from "aws-amplify";
import { workspacesByUser as WorkspacesByUser } from "../appQueries/appQueries"


export const workspacesByUser = createAsyncThunk(
    'workspaces/workspacesByUser',
    async(userID, { rejectWithValue, dispatch }) => {
        try {
            const response = await API.graphql(
                graphqlOperation(WorkspacesByUser, { userID: userID }));
            console.log('WORKSPACE', response.data)
            dispatch(listWorkspaces(response.data))
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    })

const workspacesSlice = createSlice({
    name: 'workspaces',
    initialState: {
        items: [],
        currentWorkspace: {},
        usersWorkspaces: [],
        currentWorkspaceSettings: [],
        workspaceName: "",
        currentMemberName: "",
        currentMemberEmail: "",
        currentCognitoUser: null

    },
    reducers: {
        listWorkspaces(state, action) {
            state.currentWorkspace = {}
            state.usersWorkspaces = []
            let usersWorkspaces = []
            let currentWorkspace = []
                //state.items = action.payload.workspacesByUser.items
            action.payload.workspacesByUser.items.map((workspace) => {
                usersWorkspaces = {...usersWorkspaces,
                    ... { id: workspace.workspace.id },
                    ... { name: workspace.workspace.workspaceName },
                    ... { isCurrentWorkspace: workspace.isCurrentWorkspace }
                }
                if (workspace.isCurrentWorkspace === "T") {
                    currentWorkspace = {...currentWorkspace,
                        ... { id: workspace.workspace.id },
                        ... { name: workspace.workspace.workspaceName },
                        ... { role: workspace.userRole }
                    }
                }

            })
            state.usersWorkspaces.push(usersWorkspaces)
            state.currentWorkspace = {...state.currentWorkspace, ...currentWorkspace }
        },
        setCurrentCognitoUser(state, action) {
            console.log("ACTION", action)

            state.currentCognitoUser = action.payload.signInUserSession.idToken.payload.email
        }
    },

    extraReducers: {

        [workspacesByUser.pending]: (state, action) => {
            state.fetchStatus = 'loading';
            //console.log('Users loading')
            state.error = null;
        },
        [workspacesByUser.fulfilled]: (state, action) => {
            state.fetchStatus = 'loaded'
                //console.log('Users loaded')
            state.error = null;
        },
        [workspacesByUser.rejected]: (state, action) => {
            state.fetchStatus = 'rejected';
            console.log('Workspaces rejected', action)
            state.error = action.payload
        },

    }
});

export const { listWorkspaces, setCurrentCognitoUser } = workspacesSlice.actions;
export default workspacesSlice.reducer;