import { useSelector } from "react-redux";
import {
    useAuthenticator
  } from "@aws-amplify/ui-react";

function ProfileSettings() {
  const currentWorkspace = useSelector(
    (state) => state.workspaces.currentWorkspace
  );
  const usersWorkspaces = useSelector(
    (state) => state.workspaces.usersWorkspaces
  );
  const getStatus = useSelector((state) => state.users.getStatus);
  
  return (
    <>
      <h1>Profile settings</h1>
      <p>{currentWorkspace.id}</p>
      <p>{currentWorkspace.name}</p>
      <p>{currentWorkspace.role}</p>
    </>
  );
}
export default ProfileSettings;
