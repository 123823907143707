export const getProjectNameClient = /* GraphQL */ `
  query GetProjects($id: ID!) {
    getProjects(id: $id) {
      name
    id
    client {
      id
      name
    }
  }
  }
`;

export const projectUsersByProjectsId = /* GraphQL */ `
  query ProjectUsersByProjectsId(
    $projectsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectUsersByProjectsId(
      projectsId: $projectsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        users {
          id
          name
          email
          role {
            items{
              id
            }
          }
        }
      }
    }
  }
`;


export const tasksByProjectIDAndName = /* GraphQL */ `
  query TasksByProjectIDAndName(
    $projectID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByProjectIDAndName(
      projectID: $projectID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
      id
      name
    }
    }
  }
  `;

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
      }
    }
  }
`;

export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        isBillable
        client{
          id
          name
        }
      }
    }
  }
`;

export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        email
        phone
      }
    }
  }
  `;

export const listEvents = /* GraphQL */ `
query ListEvents(
  $filter: ModelEventsFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      start
      stop
      projectID
      userID
      taskID}
  }
}
`;

export const getEvents = /* GraphQL */ `
  query GetEvents($id: ID!) {
    getEvents(id: $id) {
      id
      title
      start
      stop
      projectID
      userID
      taskID
    }
  }
  `;

export const byTime = /* GraphQL */ `
  query ByTime(
    $title: String!
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTime(
      title: $title
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
      id
      start
      stop
      projectID
      taskID
      userID
      clientID
      client {
        name
      }
      project {
        name
      }
      user {
        name
      }
      task {
        name
      }
    }
  }
}
`;

export const projectsByClientIDAndName = /* GraphQL */ `
  query ProjectsByClientIDAndName(
    $clientID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByClientIDAndName(
      clientID: $clientID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        isBillable 
        createdAt
        updatedAt
        client {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      name
      email
      phone
      defaultWorkspaceID
      defaultWorkspace {
        id
        name
    }
    }
  }
`;

export const workspacesByUser = /* GraphQL */ `
  query WorkspacesByUser(
    $userID: ID!
    $isCurrentWorkspace: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesByUser(
      userID: $userID
      isCurrentWorkspace: $isCurrentWorkspace
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        workspace {
          id
          ownerId
          workspaceName
          settings
          createdAt
          updatedAt
          owner
          __typename
        }
        userID
        userRole
        isCurrentWorkspace
        
      }
    }
  }
`;

export const usersByWorkspace = /* GraphQL */ `
  query UsersByWorkspace(
    $workspaceID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByWorkspace(
      workspaceID: $workspaceID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        workspace {
          id
          ownerId
          workspaceName
          settings
          createdAt
          updatedAt
          owner
          __typename
        }
        userID
        userRole
        userName
        memberName
        memberEmail
        isCurrentWorkspace
        status
        startWorkDate
        stopWorkDate
        address
        telephone
        workspaceEmail
        location
        bankAccount1
        bankAccount2
        hourlyRate
        costRate
        projects {
          items {
            id
            workspaceMembersId
            projectsId
            workspaceMembers {
              id
              workspaceID
              workspace {
                id
                ownerId
                workspaceName
                settings
                createdAt
                updatedAt
                owner
                __typename
              }
              userID
              userRole
              userName
              memberName
              isCurrentWorkspace
              status
              startWorkDate
              stopWorkDate
              address
              telephone
              workspaceEmail
              location
              bankAccount1
              bankAccount2
              hourlyRate
              costRate
              projects {
                items {
                  id
                  workspaceMembersId
                  projectsId
                  workspaceMembers {
                    id
                    workspaceID
                    userID
                    userRole
                    userName
                    memberName
                    isCurrentWorkspace
                    status
                    startWorkDate
                    stopWorkDate
                    address
                    telephone
                    workspaceEmail
                    location
                    bankAccount1
                    bankAccount2
                    hourlyRate
                    costRate
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  projects {
                    id
                    name
                    status
                    isBillable
                    description
                    workspaceMemberID
                    clientID
                    workspaceID
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              tasks {
                items {
                  id
                  workspaceMembersId
                  tasksId
                  workspaceMembers {
                    id
                    workspaceID
                    userID
                    userRole
                    userName
                    memberName
                    isCurrentWorkspace
                    status
                    startWorkDate
                    stopWorkDate
                    address
                    telephone
                    workspaceEmail
                    location
                    bankAccount1
                    bankAccount2
                    hourlyRate
                    costRate
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  tasks {
                    id
                    projectID
                    name
                    description
                    workspaceID
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              owner
              __typename
            }
            projects {
              id
              name
              status
              isBillable
              description
              workspaceMemberID
              tasks {
                items {
                  id
                  projectID
                  name
                  description
                  project {
                    id
                    name
                    status
                    isBillable
                    description
                    workspaceMemberID
                    clientID
                    workspaceID
                    createdAt
                    updatedAt
                    __typename
                  }
                  workspaceMember {
                    nextToken
                    __typename
                  }
                  workspaceID
                  workspace {
                    id
                    ownerId
                    workspaceName
                    settings
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              workspaceMember {
                items {
                  id
                  workspaceMembersId
                  projectsId
                  workspaceMembers {
                    id
                    workspaceID
                    userID
                    userRole
                    userName
                    memberName
                    isCurrentWorkspace
                    status
                    startWorkDate
                    stopWorkDate
                    address
                    telephone
                    workspaceEmail
                    location
                    bankAccount1
                    bankAccount2
                    hourlyRate
                    costRate
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  projects {
                    id
                    name
                    status
                    isBillable
                    description
                    workspaceMemberID
                    clientID
                    workspaceID
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              tags {
                items {
                  id
                  projectsId
                  tagsId
                  projects {
                    id
                    name
                    status
                    isBillable
                    description
                    workspaceMemberID
                    clientID
                    workspaceID
                    createdAt
                    updatedAt
                    __typename
                  }
                  tags {
                    id
                    key
                    value
                    workspaceID
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              clientID
              client {
                id
                name
                address
                email
                phone
                projects {
                  items {
                    id
                    name
                    status
                    isBillable
                    description
                    workspaceMemberID
                    clientID
                    workspaceID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                workspaceID
                workspace {
                  id
                  ownerId
                  workspaceName
                  settings
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              workspaceID
              workspace {
                id
                ownerId
                workspaceName
                settings
                createdAt
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tasks {
          items {
            id
            workspaceMembersId
            tasksId
            workspaceMembers {
              id
              workspaceID
              workspace {
                id
                ownerId
                workspaceName
                settings
                createdAt
                updatedAt
                owner
                __typename
              }
              userID
              userRole
              userName
              memberName
              isCurrentWorkspace
              status
              startWorkDate
              stopWorkDate
              address
              telephone
              workspaceEmail
              location
              bankAccount1
              bankAccount2
              hourlyRate
              costRate
              projects {
                items {
                  id
                  workspaceMembersId
                  projectsId
                  workspaceMembers {
                    id
                    workspaceID
                    userID
                    userRole
                    userName
                    memberName
                    isCurrentWorkspace
                    status
                    startWorkDate
                    stopWorkDate
                    address
                    telephone
                    workspaceEmail
                    location
                    bankAccount1
                    bankAccount2
                    hourlyRate
                    costRate
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  projects {
                    id
                    name
                    status
                    isBillable
                    description
                    workspaceMemberID
                    clientID
                    workspaceID
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              tasks {
                items {
                  id
                  workspaceMembersId
                  tasksId
                  workspaceMembers {
                    id
                    workspaceID
                    userID
                    userRole
                    userName
                    memberName
                    isCurrentWorkspace
                    status
                    startWorkDate
                    stopWorkDate
                    address
                    telephone
                    workspaceEmail
                    location
                    bankAccount1
                    bankAccount2
                    hourlyRate
                    costRate
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  tasks {
                    id
                    projectID
                    name
                    description
                    workspaceID
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              owner
              __typename
            }
            tasks {
              id
              projectID
              name
              description
              project {
                id
                name
                status
                isBillable
                description
                workspaceMemberID
                tasks {
                  items {
                    id
                    projectID
                    name
                    description
                    workspaceID
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  nextToken
                  __typename
                }
                workspaceMember {
                  items {
                    id
                    workspaceMembersId
                    projectsId
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  nextToken
                  __typename
                }
                tags {
                  items {
                    id
                    projectsId
                    tagsId
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  nextToken
                  __typename
                }
                clientID
                client {
                  id
                  name
                  address
                  email
                  phone
                  projects {
                    nextToken
                    __typename
                  }
                  workspaceID
                  workspace {
                    id
                    ownerId
                    workspaceName
                    settings
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                workspaceID
                workspace {
                  id
                  ownerId
                  workspaceName
                  settings
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              workspaceMember {
                items {
                  id
                  workspaceMembersId
                  tasksId
                  workspaceMembers {
                    id
                    workspaceID
                    userID
                    userRole
                    userName
                    memberName
                    isCurrentWorkspace
                    status
                    startWorkDate
                    stopWorkDate
                    address
                    telephone
                    workspaceEmail
                    location
                    bankAccount1
                    bankAccount2
                    hourlyRate
                    costRate
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  tasks {
                    id
                    projectID
                    name
                    description
                    workspaceID
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              workspaceID
              workspace {
                id
                ownerId
                workspaceName
                settings
                createdAt
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;