import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Hub } from "aws-amplify";
import useAuth from "../hooks/useAuth";
import {
  useNavigate,
  Link,
  useLocation,
  Outlet,
  NavLink,
} from "react-router-dom";
import Chip from "@mui/material/Chip";
import SignIn from "./auth/signIn";
import SignUp from "./auth/signUp";
import CodeConfirm from "./auth/codeConfirm";
import ForgotPassword from "./auth/forgotPassword";
import NewPassword from "./auth/newPassword";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import LogInTitle from "./auth/logInTitle";
import Loading from "./loading";
import {
  workspacesByUser,
  setCurrentCognitoUser,
} from "../store/workspacesSlice";
//import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';

export default function Login() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    flexGrow: 1,
  }));

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const fromGoogle = location.search;

  const initialState = {
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    authCode: "",
    conditionsChecked: false,
  };
  const initialMessage = {
    message: null,
    severity: null,
  };

  const [conditionsChecked, setConditionsChecked] = useState(false);
  // const { route } = useAuthenticator((context) => [context.route]);
  // console.log("LOGIN ROUTE", route)
  const { setAuth } = useAuth();
  const [uiState, setUiState] = useState("signInDialog");
  const [formState, setFormState] = useState(initialState);
  const [message, setMessage] = useState(initialMessage);
  const dispatch = useDispatch();

  const usersWorkspaces = useSelector(
    (state) => state.workspaces.usersWorkspaces
  );

  const workspacesStatus = useSelector((state) => state.workspaces.fetchStatus);

  useEffect(() => {
    console.log("HUB LOGIN Hello! From", from);
    console.log("FROM GOOGLE", fromGoogle);
    const event = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("Event", event, "Data", data);
      switch (event) {
        case "cognitoHostedUI":
          setMessage(initialMessage);
          userSignedIn(data);
          break;
        case "signIn":
          setMessage(initialMessage);
          userSignedIn(data);
          break;
        case "signIn_failure":
          setUiState("signInDialog");
          break;
        case "signUp_failure":
          setUiState("signUpDialog");
          break;
        case "forgotPasswordSubmit":
          setMessage({
            message: "Your password has been successfully changed ",
            severity: "success",
          });
          setUiState("signInDialog");
          break;
        case "tokenRefresh":
          setUiState("signInDialog");
          break;
        case "confirmSignUp":
          setMessage({
            message: "You have successfully verified your email",
            severity: "success",
          });
          setUiState("signInDialog");
          break;
        case "forgotPassword_failure":
          setUiState("forgotPasswordDialog");
          break;
        case "forgotPasswordSubmit_failure":
          setUiState("forgotPassword");
          break;
        case "autoSignIn":
          userSignedIn(data);
          break;
        case "autoSignIn_failure":
          setUiState("signInDialog");
          break;
        default:
          setUiState(event);
          break;
      }
    });
  }, []);

  // ------------------------------ userSignedIn ----------------------------
  async function userSignedIn(user) {
    console.log("User checked", user);
    try {
      setFormState(initialState);
      setMessage({ message: null, severity: null });
      //dispatch(workspacesByUser(user.signInUserSession.idToken.payload.sub));
      setAuth({ user });
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err);
      setMessage({
        message: err.message,
        severity: "error",
      });
      setUiState("signInDialog");
    }
  }

  // ---------------------------- Sign IN -----------------------------
  const signIn = async () => {
    try {
      const user = await Auth.signIn(formState.email, formState.password);
      //userSignedIn(user);
      console.log("USER SIGNED IN", user);
    } catch (err) {
      if (
        err.message ===
        "Custom auth lambda trigger is not configured for the user pool."
      ) {
        setMessage({
          message: "Password cannot be empty.",
          severity: "error",
        });
      } else {
        setMessage({
          message: err.message,
          severity: "error",
        });
      }
    }
  };
  // ------------------------ GOOGLE Sign IN --------------------------
  async function googleSignIn() {
    try {
      const user = await Auth.federatedSignIn({
        //provider: CognitoHostedUIIdentityProvider.Google,
        provider: 'Google',
      });
    } catch (err) {
      setMessage({
        message: err.message,
        severity: "error",
      });
    }
  }

  // ---------------------------- Sign UP -----------------------------
  const signUp = async () => {
    setMessage({ message: null, severity: null });
    // if (formState.password !== formState.confirmPassword) {
    //   setMessage({
    //     message: "Password is not confirmed",
    //     severity: "error",
    //   });
    // } else if (!conditionsChecked) {
    //   setMessage({
    //     message: "You must agree to the terms and conditions",
    //     severity: "error",
    //   });
    // }

    if (
      (formState.password === formState.confirmPassword) &
      formState.conditionsChecked
    ) {
      try {
        const { user } = await Auth.signUp({
          username: formState.email,
          password: formState.password,
          email: formState.email,
          attributes: {
            name: formState.userName,
          },
          autoSignIn: {
            enabled: true,
          },
        });
        setFormState({
          ...formState,
          //userName: "",
          //password: "",
          //confirmPassword: "",
          //authCode: "",
        });
      } catch (err) {
        setMessage({
          message: err.message,
          severity: "error",
        });
      }
    } else if (formState.password !== formState.confirmPassword) {
      setMessage({
        message: "Password is not confirmed",
        severity: "error",
      });
    } else if (!formState.conditionsChecked) {
      setMessage({
        message: "You must agree to the terms and conditions",
        severity: "error",
      });
    }
  };

  // ---------------------------- Code confirm -----------------------------
  const codeConfirm = async () => {
    try {
      const data = await Auth.confirmSignUp(
        formState.email,
        formState.authCode
      );
    } catch (err) {
      setMessage({
        message: err.message,
        severity: "error",
      });
    }
  };

  // ---------------------------- Resend code -----------------------------
  async function resendCode() {
    try {
      setMessage({ message: null, severity: null });
      const data = await Auth.resendSignUp(formState.email);
      setFormState({
        ...formState,
        userName: "",
        password: "",
        confirmPassword: "",
        authCode: "",
      });
      setMessage({
        message: "Your code has been resended",
        severity: "success",
      });
    } catch (err) {
      setMessage({
        message: err.message,
        severity: "error",
      });
    }
  }

  // -------------------------------- Forgot Password -----------------------------
  async function forgotPassword() {
    try {
      //setMessage({ message: null, severity: null });
      setFormState({
        ...formState,
        userName: "",
        password: "",
        confirmPassword: "",
        authCode: "",
      });
      const data = await Auth.forgotPassword(formState.email);

      //setUiState("newPassword");
    } catch (err) {
      setMessage({
        message: err.message,
        severity: "error",
      });
    }
  }

  // -------------------------------- New Password -----------------------------
  async function newPassword() {
    try {
      const data = await Auth.forgotPasswordSubmit(
        formState.email,
        formState.authCode,
        formState.password
      );
      //setUiState("signIn");
    } catch (err) {
      setMessage({
        message: err.message,
        severity: "error",
      });
    }
  }

  // -------------------------------- Sign OUT -----------------------------
  async function signOut() {
    try {
      await Auth.signOut();
      setUiState("signIn");
    } catch (err) {
      console.log(err);
      setMessage({
        message: err.message,
        severity: "error",
      });
    }
  }

  // -------------------------------- On change -----------------------------

  function onChange(e) {
    e.target.name === "conditionsChecked"
      ? setFormState({ ...formState, [e.target.name]: e.target.checked })
      : setFormState({ ...formState, [e.target.name]: e.target.value });
    setMessage({ message: null, severity: null });

    //console.log(e.target);
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          sx={{ height: "700px" }}
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {uiState === "signInDialog" && (
            <>
              <LogInTitle />
              <SignIn
                googleSignIn={googleSignIn}
                formState={formState}
                onChange={onChange}
                setUiState={setUiState}
                signIn={signIn}
                message={message}
                setMessage={setMessage}
                resendCode={resendCode}
              />
            </>
          )}
          {uiState === "signUpDialog" && (
            <>
              <LogInTitle />
              <SignUp
                signUp={signUp}
                formState={formState}
                onChange={onChange}
                setUiState={setUiState}
                message={message}
                setMessage={setMessage}
                setFormState={setFormState}
                initialState={initialState}
              />
            </>
          )}
          {uiState === "signUp" && (
            <>
              <LogInTitle />
              <CodeConfirm
                codeConfirm={codeConfirm}
                formState={formState}
                onChange={onChange}
                setUiState={setUiState}
                resendCode={resendCode}
                message={message}
                setMessage={setMessage}
              />
            </>
          )}
          {uiState === "forgotPasswordDialog" && (
            <>
              <LogInTitle />
              <ForgotPassword
                forgotPassword={forgotPassword}
                formState={formState}
                onChange={onChange}
                setUiState={setUiState}
                message={message}
                setMessage={setMessage}
                resendCode={resendCode}
              />
            </>
          )}
          {uiState === "forgotPassword" && (
            <>
              <LogInTitle />
              <NewPassword
                newPassword={newPassword}
                formState={formState}
                onChange={onChange}
                setUiState={setUiState}
                forgotPassword={forgotPassword}
                message={message}
                setMessage={setMessage}
              />
            </>
          )}
        </Stack>
      </Box>
    </>
  );
}
