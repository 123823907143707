
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, Link } from "react-router-dom";

export default function Conditions({ isAuthenticated }) {
    const navigate = useNavigate();
  return (
    <Box>
      <Typography variant="h5">TERMS AND CONDITIONS</Typography>
    </Box>
  );
}
